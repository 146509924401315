@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;700&family=Fraunces&display=swap');

@import './styles/variables';

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons";

body {
  margin-left: auto;
  margin-right: auto;
}

.trait-grid {
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.asset-grid {
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.shadow-box {
  border: 2px solid $dark;
  box-shadow: 5px 5px 0px 0px $dark;
  @extend .rounded;
}

.link-unstyled, .link-unstyled:link, .link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.underline-fancy {
  background-image: linear-gradient(120deg, $secondary 0%, lighten($secondary, 20%) 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-image 2s ease-in;
}

span.left
{
    position: relative;
}

span.left:before {
  content: "";
  position: absolute;
  height: 0.15em;
  bottom: 0;
  left: 0;
  background-color: $secondary;
  animation-name: slideInFromLeft;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes slideInFromLeft {
  from {
    visibility: hidden;
    width: 0;
  }
  to {
    visibility: visible;
    width: 105%;
  }
}

.dragging {
  border: 1px dashed #000000 !important;
  background-color: tint-color($primary, 70);
}

.btn {
  @extend .border;
  @extend .border-2;
  @extend .border-dark;
}

.btn:hover {
  border: 2px solid $dark;
  box-shadow: 5px 5px 0px 0px $dark;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

//.feature-ul li::after {
//  content: ' ✅'
//}

.feature-list dd:not(:last-child) {
  margin-bottom: 30px;
}

.landing-page-bg {
  background-color: #ffffff;
  background-image: linear-gradient(to right, fade-out(#d9fec4, .4), fade-out(#d9fec4, .4) 9.5px, $body-bg 9.5px, $body-bg);
  background-size: 19px 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  clip-path: polygon(0 0, 100% 5%, 100% 95%, 0 100%);
}

@include media-breakpoint-up(lg) {
  .bg-blob {
    z-index: -1;
    width: 600px;
    position: absolute;
    top: -230px;
    left: -150px;
  }
}
